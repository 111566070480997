@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f4f6;
	background-size: 400% 400%;
	animation: gradient 30s ease-in-out infinite;
	min-height: 70vh;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ql-container {
  height:72px!important;
  background-color:white;
}

.ql-editor {
  height:72px!important;
}


.quillCustom .ql-container {
  height:110px!important;
  background-color:white;
}

.quillCustom .ql-editor {
  height:110px!important;
}


.quillCustomB .ql-container {
  height:48px!important;
  background-color:white;

}

.quillCustomB .ql-editor {
  height:48px!important;
}


.quillCustomC .ql-container {
  height:560px!important;
  background-color:white;
  font-size:1em!important
}

.quillCustomC .ql-editor {
  height:560px!important;
}

.calendarArea {
  position:absolute;
  top:0;
  left:0;
  z-index:99999;
}


.memo-nowrap br {
  display:none;
}

#searchCompany {
  z-index:10000!important;
}

#mainTable > thead > tr > td {
 position:sticky;
 top:0;
}

.modalBg {
  z-index:99998;
}
.modalWrap {
  z-index:99999;
}

td {
  vertical-align: middle;
}

.ql-align-center {
  text-align: center;
  font-size:2em;
}